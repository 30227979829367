a[href*="maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
	display:none;
}



div.gmnoprint.gm-style-mtc{
	display: none !important;
}
.gmnoprint  .gm-style-mtc{
	display: none !important;
}
