// @import "bulma/bulma.sass";
// @import './bulmaswatch.min.css';

@import '../../../node_modules/bulmaswatch/slate/_variables.scss';

.fullContainer{
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	// border: 2px solid red;
	// background-color: grey;
}
.iconsRead{
	margin: 10px solid  !important;
}
.fchild{
	flex: 1 1 auto;
	overflow: hidden;
}
.is-fullh{
	height: 100%;
	margin: 0px;
	
}
.ffooter{
	flex: 0 0 10vh;
	max-height: 150px;
	min-height: 20px;
}
.bottonIcon{
	// border: 3px solid red;
	height: 100% !important;
	margin: 0px;
	display: flex;
	justify-content: space-evenly;
    align-items: center;
	font-size: 1.6em;
	flex-direction: column;
	.labelRt{
		font-size: 0.7em
	}
}
.icon{
	cursor: pointer;
}
.ffooter{
	padding: 0px;
}
.ACTIVELINK{
	font-size: 2em;
	>span.labelRt{
		font-size: 0.5em !important;
	}
}
// Todo lo del aside!
#vhPanel{
	position: fixed !important;
	
	height: 100vh;
	width:  70vw;
	
	top: 0px !important;
	right: -70vw;

	padding: 0px;
	
	overflow-x: hidden;
	overflow-y: auto;
	
	display: flex;
	flex-direction: column;

	&.showed{
		right: 0vw !important;
	}
	&.hidden{
		right: -70vw;
		transition-delay: 600ms, 250ms;
		transition-property: right;
	}

	>.logo{
		display: flex;
		justify-content: space-evenly;
		margin: 5px;
	}

	// Informacion del Flotilla
	>.fleet-block{
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 3px;
		// height: 60px;
		>.fleetname{
			flex: 10 1 auto;
			font-weight: 800;
			font-size: 0.8em;
			overflow: auto;
			text-overflow: ellipsis	;
			display: flex;
			flex-direction: column;
			.fleesbname{
				flex: 1 1 auto;
			}
			>.fleetcompany{
				font-size: 0.7em;
				white-space: nowrap;
			}
		}
		>.fleetcount{
			flex: 1 0 auto;
			display: flex;
		}
		>.fleetColor{
			flex: 1 0 10px;
			display: flex;
		}

		.searcinput{
			background-color:$primary;
			border-color: $primary;
			color: lighten($color: invert($primary), $amount: 30);
		}
	}
	>.logoutbtn{
		flex:  0 1 auto;
		// width: 100%;
		margin: 5px;
	}
	.vehList{
		flex: 1 1 auto;
		overflow-y: auto;
	}
	
}
#movePanel{
	position: fixed !important;
	
	height: 60px;
	width:  50px;
	
	top: 10vh !important;
	right: -10px;
	border-radius: 10px;
	
	padding: 3px;


	display: flex;
    align-content: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

	cursor: pointer;

	// &.showed{
	// 	right: 60vw;
	// }

	&>.bottonIcon{
		font-size: 1.5em;
		padding-left: 5px;
	}
	
	// background-color: blue;	
}
#reconectButton{
	position: fixed !important;
	
	height: 60px;
	width:  50px;
	
	top: 20vh !important;
	right: -10px;
	border-radius: 10px;
	
	padding: 3px;


	display: flex;
    align-content: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

	cursor: pointer;

	// &.showed{
	// 	right: 60vw;
	// }

	&>.bottonIcon{
		font-size: 1.5em;
		padding-left: 5px;
	}
	.sconect{
		color: green;
	}
	.sdconect{
		color: rgb(143, 2, 2);
	}
	
	// background-color: blue;	
}



// dentro de cada pantalla!!
.insidePanel{
	overflow-x: hidden;
	overflow-y: scroll;
	height: 100%;
}



// USRCONF
.maptyepcont{
	display: flex;
}

#fleetlist{
	margin: 0px 10px 0px 5px;
	ul{
		li{
			&.fleetItem{
				width: 100%;
				margin: 1px;
				margin-bottom: 4px;
				padding: 2px;
				display: flex;
				flex-direction: row;
				.finfo{
					flex: 1 0 auto;
					display: flex;
					flex-direction: column;
					div{
						font-size: 1.1em;
					}
					p{
						font-size: 0.8em;
					}
				}
				.fbutton{
					margin: 10px;
					flex: 0 1 auto;
					cursor: pointer;
				}
			}
		}
	}
}

// Pantalla de recorrido
#replayConf{
	display: flex;
	flex-direction: column;
	padding: 10px;
	overflow-y: auto;
    height: 100%;
	.formTable{
		width: 100%;
		tr{
			td{
				padding-bottom: 5px;
			}
		}
	}
	.box{
		flex: 0 0 auto;
	}
}
.infobottom{
	z-index: 50;
	position: fixed !important;
	height: 40px;
	width:  40px;
	top: 10vh !important;
	right: 10px;
	border-radius: 2px;
	vertical-align: middle;
	text-align: center;
	background-color: aliceblue;
	padding: 0px;
    padding-top: 8px;

	color: #8888a8;
}
// Saltos de Linea en Sweet Alert!
#swal2-html-container{
	white-space: pre-wrap;
}
.swalInfoTravel{
	// background-color: red;
	text-align: left;
}