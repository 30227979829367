@charset "utf-8";
@import 'node_modules/bulmaswatch/slate/variables.scss';
@import "node_modules/bulma/bulma.sass";
@import 'node_modules/bulmaswatch/slate/overrides.scss';

// @import './bulmaswatch.min.css';

@import 'animate.css';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
.icon_{
	font-family: allsmartt;
}
.pointer{
	cursor: pointer;
}
.mtv-20{
	margin-top: 20vh;
}
.logoImage{
	max-width: 40vw;
}
.topLogoBox{
	position: fixed;
	width: 100vw;
	margin: 0px;
	top: 0;
	right: 0;
	padding: 10px;
	text-align: center;

	z-index: 30;

}


// Helpers de texto
.tred20d{
	color: darken($color: red, $amount: 20);
}
.tgreen{
	color: green;
}

// .is-fullheight.columns {
//     // height: calc(100vh - ( 3.25rem - .75rem ) );
// }
.viewelper{
	border: 3px solid	white;

}
// .fullcontainer{
// 	width: 100vw;
// 	height: 100vh;
// 	// Deletame
// 	border: 1px solid red;
// }
#mainRouterContainer{
	border: 3px solid	blue;
	display: flex;
	flex-direction: row;
	flex-shrink: 1;

}

.taskElement{
	cursor: pointer;
}

.completedTask{
	text-decoration: line-through;
}

.BulmaImage{
	height: 25px;
}
.ACTIVELINK{
	font-weight: 800;
}


.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}