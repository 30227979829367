@import 'node_modules/bulmaswatch/slate/_variables.scss';

.vhitem-block{
	display: flex;
	flex-direction: row;
	flex: 1 1 100%;
	width: 100%;
	max-width: 100%;
	padding: 3px;
	font-size: 0.7em;
	align-items: center;
	padding-right: 8px;
    justify-content: space-evenly;
	align-items: stretch;

	>.fillitem{
		flex: 1 1 auto;
		white-space: nowrap;
		overflow-x: auto;
		padding-left: 3px;
		padding-right: 3px;
		margin: 0 5px 0 5px;
	}
	>.stretchItem{
		flex: 0 1 auto;
		font-size: 1.4em;
		margin: 0 10px 0 5px;
	}
	.namePlate{
		font-size: 0.8em;
	}
	.aliasname{
		font-size: 1.2em;
	}
	.paramicon{
		font-size: 1.5em;
		margin: 3px !important;
	}
	>.bottomicons{
		flex: 1 1 auto;
		font-size: 1.4em;
		margin: 0px;
		border-left: 1px solid;
		border-right: 1px solid;
		display: flex;
		flex-direction: column;
		align-items:center;
		cursor: pointer;
		.labelRt{
			font-size: 0.6em;
		}
		
	}
	.infoicons{
		color: greenyellow;
		font-size: 1.6em;
		vertical-align: sub;
	}

}

.trackin{
	color: $warning;
}